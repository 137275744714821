import React from "react";
import { Router } from '@reach/router';

import AccountPage  from './account';
import HomePage from './home';
import ProtectedRoute from "../components/shared/protected-route"

const IndexPage = () => {
    return (
        <>
            <Router>
                <ProtectedRoute path="/" component={HomePage} />
                <ProtectedRoute path="/account" component={AccountPage} />
            </Router>
        </>
    );
};

export default IndexPage
